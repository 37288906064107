.end-content {
    background: transparent;
    height: 350px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 1s ease;
}

.end-content .end-content_title {
    padding-top: 90px;
    padding-bottom: 50px;
    margin: auto;
    font-size: 42px;
    text-align: center;    
}

.end-content.visible {
    opacity: 1;
    transform: translateY(0);
}

.end-content .nav {
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-around;
}

.end-content .nav a {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: #fff;
    background: var(--bs-blue-1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px 50px;
    border-radius: 5px;
}

.end-content .nav a:hover {
    background: var(--bs-blue-2);
}