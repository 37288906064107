.news {
    background: linear-gradient(115deg, var(--bs-blue-3) 0, var(--bs-green-1) 90%);
    height: 700px;
}

.news .tw-container {
    max-width: 1200px;
}

.news .news-title {
    padding-top: 90px;
    padding-bottom: 50px;
    margin: auto;
    font-size: 42px;
    color: var(--bs-white);
    text-align: center;
    opacity: 0;
    transform: translateY(10px);
    transition: all 1s ease;
}

.news .news-list_post_view {
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: all 1s ease;
}

.news .visible {
    opacity: 1;
    transform: translateY(0);
}

.news .news-list_post {
    display: flex;
    width: 100%;
    height: 400px;
    z-index: 0;
}

.news .news-item {
    background-color: var(--bs-white);
    border-radius: 10px;
    width: 340px;
    flex-shrink: 0;
    padding: 20px 30px;
    position: relative;
    margin: 0px 20px;
    z-index: -1;
}

.news-item .item-title {
    padding: 20px 0px
}

.news-item .item-content {
    font-size: 16px;
}

.news-item .item-extra {
    position: absolute;
    bottom: 0;
    width: 85%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.news-item .item-detail {
    position: relative;
    color: var(--swiper-theme-color);
    text-align: center;
}

.news-item .item-detail .icon {
    padding: 0 10px;
    position: absolute;
    top: 15%;
    transform: translateX(0);
    transition: all 0.3s ease;
}

.news-item .item-detail:hover > .icon {
    transform: translateX(5px);
}

.news-item .date-upload {
    color: var(--bs-gray-500);
}