.product-intro {
    background-color: #f2f8ff;
    padding-bottom: 70px;
}

.product-intro::after {
    position: absolute;
    top: 45rem;
    left: -6%;
    content: "";
    // background: linear-gradient(270deg, #82ffff 0.04%, rgba(130, 255, 255, 0) 99.83%);
    
    opacity: 0.4;
    filter: blur(60px);
    border-radius: 70.7124px;
    height: 50%;
    width: 45%;
}

.product-intro .tw-title {
    padding-bottom: 57px;
    padding-top: 101px;
    position: relative;
    z-index: 1;
    margin: auto;
    font-size: 42px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 1s ease;
}

.product-intro .tw-content {
    padding-bottom: 120px;
}

.product-intro .slide {
    opacity: 0;
    transform: translateY(20px);
    transition: all 1s ease;
}

.product-intro .visible {
    opacity: 1;
    transform: translateY(0);
}

// .product-intro .slide-content {

// }

.product-intro .slide-content_image {
    position: relative;
    width: 51%;
    flex: 0 0 auto;
    height: 415px;
    border-radius: 10px;
    overflow: hidden;
}

.product-intro .slide-content_image img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    transition: all ease-in-out 0.5s;
}

.product-intro .slide-content_image img:hover {
    transform: scale(1.2);
    z-index: -99;
}

.product-intro .slide-content_info {
    padding: 0 80px;
    flex: 1 0 0%;
}

.product-intro .slide-content_info h1 {
    color: var(--bs-gray-600);
    padding-bottom: 0;
    margin-bottom: 45px;
}

.product-intro .slide-content_info .sub-heading {
    margin-bottom: 5px;
    display: block;
}

.product-intro .slide-content_info h3 {
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: var(--color-black);
    margin-bottom: 10px;
}

.product-intro .slide-content_info p {
    font-size: 16px;
}

.product-intro .slide-content_info .sci_btn {
    margin-top: 50px;
}

@media screen and (max-width: 940px) {
    .product-intro .slide-content_info h1 {
        display: none;
        color: var(--bs-gray-600);
        padding-bottom: 0;
        margin-bottom: 45px;
    }

    .d-flex {
        flex-direction: column;
    }

    .product-intro .slide-content_image {
        width: 100%;
        padding: 0 10px;
    }

    .product-intro .slide-content_info {
        margin-top: 30px;
        padding: 0 10px;
    }

}