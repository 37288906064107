.table-container {
    margin: 12px;
    margin: 2rem 0;
    font-family: Arial, sans-serif;
  
    .product-table {
      width: 100%;
      border-collapse: collapse;
  
      th, td {
        padding: 12px;
        border: 1px solid #ddd;
        text-align: left;
      }
  
      th {
        background-color: #f8f8f8;
        font-weight: bold;
      }
  
      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }
  