.certifications {
    background: transparent;
    height: 500px;
}

.certifications .tw-container {
    max-width: 1200px;
}

.certifications .certifications-title {
    padding-top: 90px;
    padding-bottom: 50px;
    margin: auto;
    font-size: 42px;
    text-align: center;
    opacity: 0;
    transform: translateY(10px);
    transition: all 1s ease;
    color: var(--bs-white);
}

.certifications .certifications-list_post_view {
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: all 1s ease;
    padding: 0 20px;
    max-width: 960px;
    border-radius: 10px;
    margin: auto;
}

.certifications .visible {
    opacity: 1;
    transform: translateY(0);
}

.certifications .certifications-list_post {
    display: flex;
    z-index: 0;
    animation: RightToLeft 10s infinite linear;
    overflow: visible;
    width: 960px;
    overflow: visible;
}

.certifications .certifications-item {
    display: block;
    background-color: var(--bs-white);
    border-radius: 10px;
    box-sizing: border-box;
    width: 200px;
    flex-shrink: 0;
    padding: 20px 30px;
    position: relative;
    z-index: -1;
    margin: 0px 20px;
}

.certifications-item img {
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    -o-transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.certifications-item img:hover {
    transform: scale(1.2);
}

@keyframes RightToLeft {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }

}