.header-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: 0.4s;
    background: transparent;
}

.header-content {
    max-width: 1200px;
    height: 60px;

    margin: auto;

    background: transparent;
    transition: all 0.3s ease;
    padding: 10px 0;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

// LOGO
.header-main .header-logo {
    display: block;
}

.header-main .header-logo a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
}

.header-main .header-logo img {
    object-fit: contain;
    width: 60%;
    transition: 0.4s;
}

.header-main .header-content {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

// Menu attributes
.header-main .header-content ul.menu-main {
    display: flex;
    flex-direction: row;
    height: 80%;
}

.header-main .header-content ul.menu-main > li {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    align-items: center;
}

.header-main .header-content ul.menu-main > li:not(:last-child) {
    margin-right: 35px;
}

.header-main .header-content ul.menu-main > :last-child {
    margin-right: 20px;
}

.header-main .header-content ul.menu-main>li>ul>li:hover a {
    color: var(--color-link);
}
  
// Menu link
.header-main .header-content ul.menu-main .menu-link {
    position: relative;
    display: block;
    color: var(--bs-white);

    font-size: 16px;
    font-weight: 500;
    font-family: inherit;
    line-height: 30px;

    transition: 0.4s;
    transition: all 0.3s ease;
    transform-origin: left;
}   

.header-main .header-content ul.menu-main .menu-link::after {
    display:block;
    content: '';
    border-bottom: solid 3px var(--bs-yellow-1);  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
} 


.header-main .header-content ul.menu-main .menu-link:hover {
    color: var(--bs-blue-2);
}

.header-main .header-content ul.menu-main .menu-link:hover::after {
    transform: scaleX(1);
}


// Sub-Menu
.header-main .header-content ul.menu-main > li > ul > li:not(:last-child) {
    padding-bottom: 25px;
}

.header-main .header-content ul.menu-main > li > ul {
    position: absolute;
    z-index: 10;
    left: -15px;
    background-color: #ffffff;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    flex-direction: column;
    height: auto;
    padding: 25px 20px;
    width: 270px;
    border-radius: 13px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
    transform: translateY(-10px);
}

.header-main .header-content ul.menu-main > li > ul.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}

.header-main .header-content ul.menu-main .sub-menu-link {
    position: relative;
    color: var(--bs-gray-900);
    line-height: 25px;
    font-size: 16px;
    display: block;
    font-weight: 500;
    font-family: inherit;
}   

.header-main .header-content ul.menu-main .sub-menu-link:hover {
    color: var(--bs-primary);
}   

// Shrink
.shrink .header-main .header-content .header-logo img {
    width: 50%;
}

#header-main.shrink .header-main {
    background: var(--bs-white);
    box-shadow: 0px 12px 15px rgb(9 108 216 / 10%);
}

#header-main.shrink .header-main .header-content ul.menu-main a {
    color: var(--bs-black);
}

#header-main.shrink .header-content ul.menu-main .menu-link:hover {
    color: var(--bs-blue-2);
}


// For small screen
.header-navigation.is--active .pull span {
    background-color: var(--color-heading);
}

#header-main.shrink .header-navigation .pull span {
    background-color: var(--color-heading);
}


.header-navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: transparent;
    padding: 0 15px;
    z-index: 99;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: none;
}

.header-navigation .header-logo {
    display: block;
}

.header-navigation .header-logo a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
}

.header-navigation .header-logo img {
    object-fit: contain;
    width: 90%;
    transition: 0.4s;
}

.header-navigation .pull span {
    width: 20px;
    height: 3px;
    background-color: var(--bs-white);
    margin-bottom: 5px;
    display: block;
    border-radius: 2px;
}

.header-navigation.is--active .pull span {
    background-color: var(--bs-primary);
}

#header-main.shrink .header-navigation .pull span {
    background-color: var(--bs-primary);
}

.header-navigation .pull span:last-child {
    margin-bottom: 0;
}

.header-navigation .pull span:nth-of-type(1),
.header-navigation .pull span:nth-of-type(3) {
    transition: 0.4s;
}

.header-navigation .pull.is--change span:nth-of-type(2) {
    opacity: 0;
}

.header-navigation .pull.is--change span:nth-of-type(1) {
    transform: rotate(42deg) translate(8px, 4px);
    width: 26px;
}

.header-navigation .pull.is--change span:nth-of-type(3) {
    transform: rotate(-42deg) translate(7px, -4px);
    width: 26px;
}

@media screen and (max-width:765px) {
    /* Header */
    .header-main .header-content ul.menu-main > li > ul {
        display: none;
      }
    
      .header-main .header-content ul.menu-main > li > ul.show {
        display: block; 
        position: static; 
        box-shadow: none; 
        width: auto; 
      }
    
      .header-main .header-content ul.menu-main .sub-menu-link {
        font-size: 18px; 
        padding: 10px 0;
      }
    
      .header-main .header-content ul.menu-main > li:hover > ul.show {
        display: block;
      }
    .header-main {
        bottom: 0;
        right: 0;
        top: 60px;
        transform: translateX(-100%);
    }

    .header-main.is--active {
        transform: translateX(0);
        background: var(--bs-gray-1);
        height: 100%;
        overflow: hidden;
    }

    .header-main .header-content {
        background: var(--color-white);
    }

    .header-main .header-content {
        height: 100%;
        padding: 0px 5%;
    }

    .header-main .header-content .header-logo {
        display: none;
    }

    .header-main .header-content ul.menu-main .menu-link {
        color: var(--bs-black);
        font-size: 24px;
        line-height: 28px;
    }

    .header-main .header-content ul.menu-main .menu-link::after {
        border: none;
    }

    .header-main .header-content ul.menu-main .menu-link:hover {
        color: var(--bs-primary)
    }

    .header-main .header-content ul.menu-main > li > ul, .icon {
        display: none;
    }
    
    .header-main .header-content ul.menu-main > li > ul.show {
        // Hover will not effective for any mobile device
        display: none
    }

    .header-main .header-content ul.menu-main .sub-menu-link {
        position: relative;
        color: var(--bs-gray-900);
        line-height: 25px;
        font-size: 24px;
        display: block;
        font-weight: 500;
        font-family: inherit;
        margin: 10px 20px;
    }   

    #header-main .header-main.is--active .header-content,
    .header-navigation.is--active {
        background: var(--bs-gray-1);
        border-bottom: none;
    }

    #header-main.shrink .header-main {
        background: var(--bs-gray-1);
    }

    #header-main.shrink .header-navigation {
        background: var(--bs-white);
        box-shadow: 0px 12px 15px rgb(9 108 216 / 10%);
    }

    #header-main.shrink .header-navigation .header-logo img {
        width: 80%;
    }

    #header-main.shrink .header-navigation.is--active {
        background: var(--bs-gray-1);
    }

    .header-main .header-content ul.menu-main {
        flex-direction: column;
        margin-top: 0;
    }

    .header-main .header-content ul.menu-main > li {
        margin: 0;
        margin-bottom: 15px;
    }

    .header-main .header-content ul.menu-main > li > ul {
        background: var(--bs-gray-1);
        margin-top: 14px;
        position: initial;
        box-shadow: unset;
        padding: 0;
        width: 100%;
    }

    .header-navigation {
        display: flex;
    }
}