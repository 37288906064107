.contact_btn {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10%;
    right: 60px;
}

.mypage-alo-ph-circle {
    width: 90px;
    height: 90px;
    top: 12px;
    left: 12px;
    position: absolute;
    background-color: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid rgba(30, 30, 30, 0.4);
    opacity: .1;
    border-color: #65d598;
    opacity: .5;
}

.mypage-alo-ph-circle-fill {
    width: 60px;
    height: 60px;
    top: 28px;
    left: 28px;
    position: absolute;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-color: rgb(65 204 128);
    opacity: .75 !important;
}

.mypage-alo-ph-img-circle {
    width: 42px;
    height: 42px;
    top: 37px;
    left: 37px;
    position: absolute;
    background: rgba(30, 30, 30, 0.1) url(../../assets/img/index/whatapp-icon2.png) no-repeat center center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: .7;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-color: #4ecf88;
    background-size: 120%;
}

.animated {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3);
        -ms-transform: scale(0.3);
        transform: scale(0.3);
    }
    50% {
        opacity: 1;
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}

@keyframes tada {
    0% {
        transform: scale(1);
    }
    10%, 20% {
        transform: scale(0.9) rotate(-3deg);
    }
    30%, 50%, 70%, 90% {
        transform: scale(1.1) rotate(3deg);
    }
    40%, 60%, 80% {
        transform: scale(1.1) rotate(-3deg);
    }
    100% {
        transform: scale(1) rotate(0);
        opacity: 1;
    }
}