body {
  margin: 0;
  padding: 0;
}

.des-impact {
  max-width: 750px;
}

.title-impact {
  font-size: 30px;
}

.img-fluid {
  border-radius: 6px;
  width: 700px; /* Keep the original width on larger screens */
  height: 400px;
}

.row {
  display: flex;
  /* Original layout: horizontal on desktop screens */
}

._left {
  flex: 1;
  margin-left: 200px; /* Keep original margin */
}

._right {
  flex: 1;
  text-align: left;
  max-width: 1000px; /* Keep original width */
}

.text-header {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.des {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-right: 800px; /* Original margin */
  width: 700px; /* Keep original width */
}

.whoweare {
  display: flex;
  margin: 20px 0; /* Keep original margin */
}

/* MEDIA QUERIES FOR RESPONSIVENESS */
@media (max-width: 768px) {
  .row {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center the items */
  }

  ._left {
    margin-left: 0;
    text-align: center;
  }

  .text-header {
    font-size: 28px; /* Slightly smaller font for headers */
  }

  .des {
    font-size: 14px; /* Smaller font size for mobile */
    margin-right: 0; /* Remove the extra right margin */
    width: 100%; /* Make text width responsive */
  }

  ._right {
    max-width: 100%; /* Full width for text on mobile */
    padding: 20px; /* Add padding for readability */
  }

  .img-fluid {
    width: 100%; /* Full width for images */
    height: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 480px) {
  .text-header {
    font-size: 24px; /* Further reduce the size for smaller screens */
  }

  .des {
    font-size: 12px; /* Smaller text on very small screens */
  }

  ._right {
    padding: 10px; /* Reduce padding on very small screens */
  }
}
