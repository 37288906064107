@font-face {
  font-family: Rubik;
  src: local("Rubik-Regular"),
    url(../fonts/Rubik-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Rubik;
  src: local("Rubik-Medium"),
    url(../fonts/Rubik-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  src: local("Poppins-Regular"),
    url(../fonts/Poppins-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  src: local("Poppins-Medium"),
    url(../fonts/Poppins-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/* @font-face {
  font-family: Poppins;
  src: local("Poppins-MediumItalic"),
    url(../fonts/Poppins-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
} */
@font-face {
  font-family: Poppins;
  src: local("Poppins-SemiBold"),
    url(../fonts/Poppins-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  src: local("Poppins-Bold"),
    url(../fonts/Poppins-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* @font-face {
  font-family: Poppins;
  src: local("Poppins-ExtraBold"),
    url(../fonts/Poppins-ExtraBold.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
} */
/*# sourceMappingURL=../sourcemaps/preload.css.map */
