.footer {
    background: #f2f8ff;
    color: var(--bs-black);
    padding: 80px 0 40px;
}

.footer-container {
    max-width: 1280px;
    margin: auto;
    padding: 0px 30px;
    // max-height: fit-content;
}

.footer .map-contact {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
}

.map-contact .footer_main_map {
    margin-right: 15%;
}

.footer img {
    width: 300px;
    height: 414px;
}

.footer_main {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.footer_main-block--nav {
    margin-bottom: 40px;
}

.footer_main-block .list {
    overflow-wrap: normal;
}

.footer_main-block--nav .list-item {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
}

.footer_main-block--nav .title {
    font-size: 20px;
    line-height: 1.2;
    color: var(--bs-black);
    margin-bottom: 30px;
}

.footer_main-block--nav .list-item:last-of-type {
    margin-bottom: 0;
}

.footer_main-block--nav .list-item .icon {
    padding-right: 10px;
    position: relative;
    top: -1px;
    left: 0;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    color: var(--bs-black);
    display: block;
}

.footer_main-block--nav .list-item .icon_svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.footer_main-block--nav .list-item:hover {
    color: var(--bs-blue-1);
}

.footer_copyrights {
    text-align: center;
    color: var(--bs-black);
    border-top: 1px solid var(--bs-gray-600);
    padding: 20px;
}

.footer_link {
    display: flex; 
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 5%;
}

.footer_extra_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer_extra_section a {
    color: black;
    font-weight: 500;
}

.footer_extra_section a:hover {
    color: var(--bs-blue-1);
}


@media screen and (max-width: 680px) {
    .map-contact .footer_main_map {
        margin-bottom: 20px;
        margin-left: 10%;
    }

    .footer .map-contact {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .footer_link {
        flex-direction: column;
        padding: 0px;
    }

    .footer_extra_section a {
        width: 30%;
    }
}

@media screen and (min-width: 700px) {
    .footer_extra_section {
        width: 60%;
    }
}


@media screen and (min-width: 1000px) {
    .footer {
        padding: 70px 0 40px;
    }
    
    .footer .map-contact {
        display: flex;
        width: 70%;
        margin-bottom: 20px;
    }

    .footer_main {
        margin-bottom: 100px;
        justify-content: space-between;
        flex-direction: row;
    }

    .map-contact .footer_main_map {
        margin-right: 10%;
    }

    .footer_main-block--nav {
        margin-bottom: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: center;
    }

    .footer_main-block--nav .list-item {
        margin-bottom: 15px;
    }

    .footer_main-block--subscribe {
        max-width: 380px;
    }

    .footer_info-block {
        width: calc(100% / 3);
        padding: 40px 30px;
    }

    .footer_info {
        gap: 20px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .footer_info-block {
        width: calc(50% - 10px);
    }

    .footer_link {
        flex-direction: column;
        padding: 0px;
    }

    .footer_extra_section {
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .map-contact .footer_main_map {
        margin-right: 20%;
    }
}
