@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.product-header {
    animation: fadeIn 1s ease-out;
}

.product-body {
    background-color: #f0f2f5; 

}
.margin-page {
    margin: 20px;
    color: #333333;
    border-radius: 10px;
}
.product-header {
    margin-top: 90px;
    text-align: center;
}
.custom-header-color .menu-link {
    color: black !important;
  }
  .custom-header-color .icon-caret_down {
    color: rgb(1, 1, 213); 
  }
.image-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.paint-image {
    max-width: 700px; 
    width: 100%; 
    height: auto;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.action-button {
    background-color: #738fad;
    color: #fff;
    border: none;
    width: 218px; 
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 0.9em; 
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
}

.action-button:hover {
    background-color: #0056b3;
}

.table-container {
    margin-top: 20px;
}
table {
    width: 100%;
    border-collapse: collapse;
    border-color: #000000;
    margin: 20px 0;
    font-size: 1em;
    text-align: left;
    background-color: #ffffff;
}

th, td {
    padding: 12px 15px;
    border: 1px solid #000000;
}

th {
    background-color: #ffffff;
    text-align: center;
    color: #0056b3;

}

tr:nth-child(even) {
    background-color: #ffffff;
}

.sub-header {
    background-color: #ffffff;
    text-align: center;
    font-weight: bold;
}
.applications {
    margin: 20px 0;
    padding: 20px;
    background-color: #f9f9f9; 
    border-radius: 8px; 
}

.applications h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
}

.applications ul {
    list-style-type: disc;
    margin-left: 20px;
}

.applications p {
    margin-top: 10px;
    line-height: 1.6;
}

    .image-buttons-container {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    .paint-image {
        max-width: 700px;
        height: auto;
        margin-right: 20px;
        flex: 1;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
    }

    .action-button {
        background-color: #738fad;
        color: #fff;
        border: none;
        width: 218px;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 1em;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.3s;
    }

    .action-button:hover {
        background-color: #0056b3;
    }

.table-container {
    margin-top: 20px;
}

@media (max-width: 480px) {
    .product-header {
        margin-top: 40px;
        font-size: 1.2em;
    }

    table {
        font-size: 0.8em;
    }

    th, td {
        padding: 6px;
    }

    .applications h2 {
        font-size: 1em;
    }

    .applications ul {
        margin-left: 15px;
    }
}
    
.contact-form1-container button {
    width: 120px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: black;
    border-radius: 4px;
    cursor: pointer;
}
.contact-form1-container button:hover {
    background-color: #0056b3;
}
.action-button {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}
