.product-body{
    margin-top: 70px;
    margin-left: 20px;
    margin-right: 20px;
}

.product-list {
    margin: 20px 0;
}

.product-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
}

.product-item-page {
    width: calc(25% - 20px);
    text-align: center;
}

.product-image {
    width: 100%;
    max-width: 850px; 
    height: auto;
    object-fit: cover;
}

.product-name {
    margin-top: 10px;
    font-size: 16px; }
