/* General container styling */
.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.contact-form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  flex-wrap: wrap;
}

/* Contact form and image container */
.contact-form, 
.contact-image {
  flex: 1;
  max-width: 650px;
  max-height: 650px;
  width: 100%;
}

/* Contact form styling */
.contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-image img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Animation for floating in effect */
.contact-info,
.contact-form-container {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.contact-info.visible,
.contact-form-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.contact-info {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 20px 0;
  margin-left: 10%;
  margin-right: 10%;
}

.info-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  text-align: left;
}

.contact-info .info-item p {
  width: 150px;
  white-space: normal;
  overflow-wrap: break-word;
}

/* Icon styling */
.icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00aaff;
  margin-right: 10px;
}

.icon-circle i {
  font-size: 24px;
  color: #fff;
}

.info-item h4 {
  margin: 0;
  font-size: 16px;
  color: #000;
}

.info-item p {
  margin: 0;
  font-size: 14px;
  color: #ffffff;
}

/* Contact form styling */
.contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.contact-form h1 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

button {
  padding: 10px 20px;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #007acc;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .info-item {
    flex-direction: column;
    text-align: center;
    padding: 10px 0;
  }

  .contact-form-container {
    flex-direction: column;
    padding: 20px 0;
  }

  .contact-form {
    max-width: 100%;
    padding: 20px;
  }

  .contact-image {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .form-group input,
  .form-group textarea {
    width: 100%;
  }

  .contact-form h1 {
    font-size: 24px;
  }

  .contact-info {
    margin-top: 20px;
    width: 100%;
  }

  .info-item {
    padding: 5px 0;
  }

  .icon-circle {
    width: 40px;
    height: 40px;
  }

  .icon-circle i {
    font-size: 20px;
  }
}
