* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins, sans-serif;
}

:root {
    --bs-white: #fff;
    --bs-gray-1: #f2f8ff;
    --bs-blue-1: #0288D1;
    --bs-blue-2: #2A93EC;
    --bs-blue-3: #0F67B1;
    --bs-yellow-1: #ffc107;
    --bs-green-1: #017280;
}

body {
    font-size: 17px;
    line-height: 1.5;
}

.section-2 {
    background: linear-gradient(115deg, var(--bs-blue-3) 0, var(--bs-green-1) 90%);
    padding: 10px 0;
}

// Scroll bar
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: var(--bs-gray-1);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #A9A9A9;
}

::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

ol, ul {
    list-style: none;
}

a {
    text-decoration: none;
}

a, button, input, textarea {
    background: 0 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: none;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

input, textarea {
    border-radius: 0;
}

input[readonly], textarea[readonly] {
    cursor: default;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

input::placeholder,
textarea::placeholder {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

textarea {
    resize: none;
}

img {
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeIn {
    -webkit-animation: 0.4s ease-in-out fadeIn;
    animation: 0.4s ease-in-out fadeIn;
}

.fadeOut {
    -webkit-animation: 0.4s ease-in-out fadeOut;
    animation: 0.4s ease-in-out fadeOut;
}

html.fixed {
    overflow: hidden;
}

.section {
    padding: 60px 0;
}

h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--bs-blue-3);
}

h1, .h1 {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.1;
}

h2, .h2 {
    font-size: 34px;
    line-height: 1.3;
    font-weight: 700;
}

h3, .h3 {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 700;
}

h4, .h4 {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
}

h5, .h5 {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
}

h6, .h6 {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
}

.container {
    max-width: 1180px;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
}

.fade {
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.fade:not(.show) {
    opacity: 0;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.logo {
    height: 37px;
}

.logo_media {
    margin-right: 6px;
}

.logo_media svg {
    position: relative;
    top: 4px;
}

.logo_text {
    font-weight: 600;
    font-size: 32px;
    line-height: 1;
    position: relative;
}

.link--arrow {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    font-size: 14px;
}

.link--arrow .icon {
    margin-left: 5px;
    -webkit-transition: margin-left 0.4s ease-in-out;
    -o-transition: margin-left 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out;
}

.link--arrow:hover .icon {
    margin-left: 10px;
}

.btn {
    color: #fff;
    background: var(--bs-blue-1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.btn--split {
    height: 54px;
    min-width: 270px;
    position: relative;
}

.btn--split:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: var(--bs-blue-2);
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}

.btn--split .icon,
.btn--split .main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.btn--split .main {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: calc(100% - 54px);
}

.btn--split .icon {
    width: 54px;
    background: var(--bs-blue-2);
    height: 100%;
}

.btn--split:focus:before,
.btn--split:hover:before {
    width: 100%;
}

.btn--regular {
    height: 44px;
    min-width: 180px;
}

.btn--regular.bordered {
    background: 0 0;
    border: 1px solid #fff;
}

.btn--regular:focus,
.btn--regular:hover {
    background-color: var(--bs-blue-2);
}

.btn--regular,
.btn--split {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
}

.tw-container {
    max-width: 1170px;
    margin: 0 auto;
}

@media screen and (min-width: 374.98px) {
    .container {
        padding: 0 30px;
    }
}

@media screen and (min-width: 474.98px) {
    .contact_data-item .media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media screen and (min-width: 575.98px) {
    .metadata {
        gap: 5px 40px;
    }

    .contact_data-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
    }

    .contact_data-item .main,
    .contact_data-item .media {
        padding: 0;
    }

    .contact_data-item .media {
        width: 120px;
    }

    .contact_data-item .main {
        width: calc(100% - 120px);
        padding: 20px 30px 20px 20px;
    }
}

@media screen and (min-width: 768px) {
    .section {
        padding: 80px 0;
    }

    .h1,
    h1 {
        font-size: 42px;
    }

    .h2,
    h2 {
        font-size: 38px;
    }

    .h3,
    h3 {
        font-size: 34px;
    }

    .tw-container {
        padding: 0 30px;
    }
}