.section_04.our-teams-common {
    padding: 137px 0 303px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: "TT Commons Regular";
    font-size: 17px;
    line-height: 23px;
    color: black;
}
*{
    box-sizing: border-box;

}

div {
    display: block;
    unicode-bidi: isolate;
}
@media (max-width: 1200px) {
    .tw-container {
        padding: 0 50px;
    }
}

.tw-container {
    max-width: 1170px;
    margin: 0 auto;
}

.d-flex {
    display: flex !important;
}

.section_04.our-teams-common .tw-content .__left {
    margin-right: 72px;
}

.section_04.our-teams-common .tw-content .__left h2 {
    width: 223px;
}

.__left .h2-text {
    color: var(--color-black);
    min-width: 175px;
}

.h2-text {
    font-weight: 500;
    font-style: normal;
    font-size: 58px;
    line-height: 62px;
    letter-spacing: -0.01em;
    color: white;
    margin-bottom: 20px;
}
.our-teams-common .tw-content ._our_team_item {
    overflow: hidden;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
.our-teams-common .tw-content ._our_team_item .swiper-slide {
    height: auto;
    align-self: stretch;
}
.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.our-teams-common .tw-content ._our_team_item .__item {
    width: 100%;
    margin-right: 16px;
    background: white;
    border-radius: 10px;
    padding: 37px 30px 32px 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
}
a {
    color: rgb(17, 156, 255);
    text-decoration: none;
}

.our-teams-common .tw-content ._our_team_item .__item .--txt {
    margin-bottom: 30px;
}

.__right p {
    margin-bottom: 15px;
}

h1, .h2-text, h3, h4, h5, h6, p {
    margin: 0;
}
.section_04 .slide-list-team .swiper-pagination-our-team {
    margin-top: 47px;
}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
}
.our-teams-common .tw-content ._our_team_item .__item .btn-view-detail {
    margin-top: auto;
}

.btn-view-detail-home {
    margin-top: 50px;
}

.btn-view-detail {
    font-family: "TT Commons Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-link);
    display: flex;
    align-items: center;
}
