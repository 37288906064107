.slider {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    //   padding-top: 120px;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.slider-media,
.slider-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.slider-media {
    z-index: -1;
}

.slider-media-slide img {
    height: 100%;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: -webkit-transform 5s linear;
    transition: transform 5s linear;
    -o-transition: transform 5s linear;
    transition: transform 5s linear, -webkit-transform 5s linear;
}

.slider-media-slide.swiper-slide-duplicate-active img,
.slider-media-slide.swiper-slide.swiper-slide-active img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.slider-overlay {
    background: linear-gradient(135deg,
            rgba(15, 103, 177, 0.2) 0,
            rgba(1, 114, 128, 0.4) 100%);

    opacity: 1;
    -webkit-transition: opacity 1s linear;
    -ms-transition: opacity 1s linear;
    transition: opacity 1s linear;
    
}

.slider-overlay.remove-background {
    opacity: 0;
}

.slider-content {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: var(--bs-white);
}

.slider-content_title {
    color: #fff;
}

.slider-content_text {
    max-width: 680px;
    margin: 15px 0 30px;
    font-weight: 500;
}

.slider_service {
    display: none;
}

@media screen and (min-width: 767.98px) {
    .slider-content_title {
        max-width: 700px;
    }

    .slider-content_text {
        margin: 30px 0 40px;
        padding-left: 20px;
        border-left: 7px solid #ffcc29;
    }
}

@media screen and (min-width: 991.98px) {
    //   .slider {
    //     min-height: calc(100vh + 180px);
    //   }

    .slider-content {
        max-width: 710px;
    }

    .slider-content_text {
        margin: 30px 0 50px;
    }
}

@media screen and (min-width: 1279.98px) {
    .slider .container {
        position: relative;
    }

    .slider_service {
        display: block;
        position: absolute;
        z-index: 2;
        right: 0;
        top: -40px;
        width: 430px;
        height: 520px;
    }

    .slider_service-card {
        width: 200px;
        height: 260px;
        overflow: hidden;
        position: absolute;
    }

    .slider_service-card:first-of-type {
        top: 0;
        left: 0;
        z-index: 4;
    }

    .slider_service-card:nth-of-type(2) {
        right: 50px;
        bottom: 20px;
        z-index: 3;
        -webkit-box-shadow: 0 2px 25px rgba(0, 0, 142, 0.15);
        box-shadow: 0 2px 25px rgba(0, 0, 142, 0.15);
    }

    .slider_service-card img {
        height: 100%;
    }

    .slider_service-label {
        position: absolute;
        right: 50px;
        top: 70px;
    }

    .slider_service-label .title {
        color: #fff;
        font-weight: 500;
        line-height: 2;
        position: relative;
        margin-bottom: 3px;
    }

    .slider_service-label .title:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #ffcc29;
        left: -22px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media screen and (min-width: 1365.98px) {
    .slider_service {
        left: 790px;
    }
}

@media screen and (min-width: 1535.98px) {
    .slider_service {
        width: 570px;
        height: 736px;
    }

    .slider_service-card {
        width: 326px;
        height: 405px;
    }

    .slider_service-card:nth-of-type(2) {
        bottom: -10px;
    }
}

@media screen and (min-width: 1919.98px) {
    .slider_service-card {
        height: 450px;
    }

    .slider_service-card:nth-of-type(2) {
        bottom: -110px;
        right: 0;
    }

    .slider_service-label {
        right: 0;
    }
}

.slider-content_title.fade-up {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.slider-content_text.fade-up {
    opacity: 0;
    transform: translateY(75px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.slider-content_btn.fade-up {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}


.fade-up.visible {
    opacity: 1;
    transform: translateY(0);
}