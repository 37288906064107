.logistic-service {
    background-color: #f2f8ff;
    padding: 0 30px;
    padding-bottom: 150px;
}

.logistic-service .tw-container {
    opacity: 0;
    transform: translateY(30px);
    transition: all 1s ease;
}

.logistic-service .visible {
    opacity: 1;
    transform: translateY(0);
}

.logistic-service .d-flex {
    justify-content: space-between;
}

.logistic-service .ls-content {
    height: 50%;
    width: 45%;
    margin-right: 50px;
}

.logistic-service .ls-title {
    padding-bottom: 15px;
    position: relative;
    z-index: 1;
    margin: auto;
    font-size: 42px;
}

.logistic-service .ls-btn {
    margin-top: 30px;
}

.logistic-service .ls-number {
    margin-right: 40px;
}


.logistic-service .ls-numbers_item {
    min-width: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
}

.logistic-service .ls-numbers_item:last-of-type {
    margin-bottom: 0;
}

.ls-numbers_item:nth-of-type(odd) .wrapper .countNum,
.ls-numbers_item:nth-of-type(odd) .wrapper .suffix {
    color: var(--bs-blue-3) !important;
}

.ls-numbers_item:nth-of-type(even) .wrapper .countNum,
.ls-numbers_item:nth-of-type(even) .wrapper .suffix {
    color: var(--bs-green-1) !important;
}

.ls-numbers_item .countNum {
    line-height: 1;
}

.ls-numbers_item .suffix--plus {
    font-weight: 400;
}

.ls-numbers_item .suffix--word {
    font-size: 20px;
    font-family: Poppins, sans-serif;
    position: relative;
    left: -2px;
}

.ls-gallery {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-top: 60px;
}

.ls-gallery_item {
    height: 300px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.ls-gallery_item img {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    -o-transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    height: 100%;
    width: calc(100% + 1px);
}

.ls-gallery_item:hover img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

@media screen and (max-width: 940px) {
    .logistic-service {
        padding: 0 10px;
        margin-bottom: 150px;
    }

    .logistic-service .ls-content {
        height: 50%;
        width: 75%;
        margin-right: 50px;
    }

    .ls_main-numbers {
        margin-top: 50px;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }

    .ls_main-numbers .wrapper, .ls_main-numbers .label {
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .logistic-service {
        padding: 0 10px;
    }

    .logistic-service .ls-content {
        height: 50%;
        width: 100%;
    }

    .logistic-service .ls-number {
        margin: 0px;
    }

    .ls_main-numbers {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;
    }

    .ls_main-numbers .wrapper {
        text-align: center;
    }
}

@media screen and (min-width: 500px) {
    .ls-gallery {
        grid-template-columns: repeat(3, 1fr);
    }
}
