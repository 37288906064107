@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.product-header {
    animation: fadeIn 1s ease-out;
}

.product-body {
    background-color: #f0f2f5; 
}

.margin-page {
    margin: 20px;
    color: #333333;
    border-radius: 10px;
}

.product-header {
    margin-top: 90px;
    text-align: center;
}

.custom-header-color .menu-link {
    color: black !important;
}

.custom-header-color .icon-caret_down {
    color: rgb(1, 1, 213); 
}

.custom-header-color .header-navigation .pull span {
    background-color: var(--bs-primary);
}

.image-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.paintt-image {
    max-width: 500px; 
    width: 100%; 
    height: auto;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.action-button {
    background-color: #738fad;
    color: #fff;
    border: none;
    width: 218px; 
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 0.9em; 
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
}

.action-button:hover {
    background-color: #0056b3;
}

.table-container {
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-color: #000000;
    margin: 20px 0;
    font-size: 1em;
    text-align: left;
    background-color: #ffffff;
}

th, td {
    padding: 12px 15px;
    border: 1px solid #000000;
}

th {
    color: #0056b3;
    background-color: #ffffff;
    text-align: center;
}

tr:nth-child(even) {
    background-color: #ffffff;
}

.sub-header {
    background-color: #ffffff;
    text-align: center;
    font-weight: bold;
}

.applications {
    margin: 20px 0;
    padding: 20px;
    background-color: #f9f9f9; 
    border-radius: 8px; 
}

.applications h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
}

.applications ul {
    list-style-type: disc;
    margin-left: 20px;
}

.applications p {
    margin-top: 10px;
    line-height: 1.6;
}

@media (max-width: 480px) {
    .product-header {
        margin-top: 40px;
        font-size: 1.2em;
    }

    table {
        font-size: 0.8em;
    }

    th, td {
        padding: 6px;
    }

    .applications h2 {
        font-size: 1em;
    }

    .applications ul {
        margin-left: 15px;
    }
}

/* New styles for contact form */
.contact-form1-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.contact-form1-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    z-index: 1001;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-form1-container h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.contact-form1-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.contact-form1-container input,
.contact-form1-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form1-container button {
    width: 120px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: black;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form1-container button:hover {
    background-color: #0056b3;
}

.action-button {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}
