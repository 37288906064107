/* Base container styles */
.relative {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  animation: slideBackground 15s infinite; /* Keep background animation */
  
  
  /* Removed blur effect */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 51, 102, 0.5); /* Keep the dark overlay */
    z-index: 1; /* Ensure it's below the dot grid and text */
    transition: opacity 0.5s ease;
  }

  &.blur-on::before {
    opacity: 0;
  }

  &.blur-off::before {
    opacity: 0;
  }
}

.image-slider {
  .image-item {
    /* Keep transition effects for other images */
    transition: opacity 1s ease, transform 1s ease;
    
    &.first-image.no-transition {
      transition: none; /* Remove transition for the first image */
    }
  }
}


/* Background slide animation */
@keyframes slideBackground {
  0% {
    background-image: url('../../assets/img/about-us/back4.jpeg');
  }
  25% {
    background-image: url('../../assets/img/about-us/back2.jpg');
  }
  50% {
    background-image: url('../../assets/img/about-us/back3.jpg');
  }
  75% {
    background-image: url('../../assets/img/about-us/background-image.jpg');
  }
  100% {
    background-image: url('../../assets/img/about-us/back4.jpeg');
  }

}

/* Dot Grid */
.grid {
  display: grid;
  gap: 0rem; /* Adjust gap between dots if needed */
  position: absolute;
  z-index: 5; /* Ensure dots appear above the overlay, but below the text */
  right: 15%; /* Move grid to the right */
}

/* Dot point styles */
.dot-point {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  background-image: linear-gradient(to bottom, #fafafa, #bac5d5);
  opacity: 0.1;
}

.group-hover .dot-point {
  background-image: linear-gradient(to bottom, #4f46e5, #ffffff);
}

/* Text container styles */
.text-container {
  position: absolute;
  z-index: 10;
  text-align: left;
  color: white;
  opacity: 0;
  max-width: 600px;
  top: 25%;
  left: 25%;
  transform: translate(-50%, -50%);
  
  .h1-text {
    font-size: 6rem;
    margin: 0.5rem 0;
    font-weight: bold;
    color: #ffffff !important;
    text-shadow: 0 0 15px var(--bs-blue-1), 0 0 30px var(--bs-blue-1), 0 0 45px var(--bs-blue-1); /* Glowing effect */
  }
  .highlight {
    font-size: 6rem;
    margin: 0.5rem 0;
    font-weight: bold;
    color: var(--bs-blue-1) !important;
    text-shadow: 0 0 15px #fff, 0 0 30px #fff, 0 0 45px #fff; /* Glowing effect */
  }

  h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
    text-shadow: 0 0 10px var(--bs-blue-1), 0 0 20px var(--bs-blue-1); /* Glowing effect */
  }
  .highlight2 {
    font-size: 2rem;
    margin: 0.5rem 0;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff; /* Glowing effect */
  }

  p {
    font-size: 1rem;
    margin: 0.5rem 0;
    line-height: 1.5;
    text-shadow: 0 0 5px var(--bs-blue-1); /* Glowing effect */
  }
}

/* Media query for phone screens */
@media (max-width: 768px) {
  .text-container {
    top: 15%;
    left: 10%;
    transform: none;
    max-width: 90%;

    .h1-text {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
}

.contact-button {
  color: #fff;
  background: var(--bs-blue-1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #4f46e5;
}
