.contact-form1-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

}

.contact-form1-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
}

.contact-form1-container h2 {
    margin-bottom: 20px;
}

.contact-form1-container label {
    display: block;
    margin-bottom: 10px;
}

.contact-form1-container input,
.contact-form1-container textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-actions {
    display: flex;
    justify-content: space-between;
}

.form-actions button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.form-actions button[type="button"] {
    background-color: #6c757d;
}
