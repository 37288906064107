.center-video {
    display: flex;
    justify-content: center;
  }
  
.header-timeline {
    margin-left: 12%;
    margin-top: 150px;
}

.timeline-container {
    background-color: rgb(193, 253, 255); /* or any other color you prefer */
    padding: 20px;
    border-radius: 8px;
  }
  
  .center-video {
    position: relative;
    z-index: 10;
    padding-top: 10px;
  }
  
  .center-video video {
    width: 100%;
    height: auto;
  }
  